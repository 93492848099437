import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Seo from "../../components/Seo";
import Layout from "../../components/layout";
import InstagramFeed from "../../components/InstagramFeed";
import HeroHeader from "../../components/HeroHeader";
import StepsToStart from "../../sections/StepsToStart";
import FreeTrialForm from "../../components/Forms/FreeTrialForm";
import OurLocations from "../../sections/OurLocations";
import { navigate } from "gatsby-link";

const stepsToStartId = "steps";
const membershipOptionsId = "membership-options";
const freeTrialId = "free-trial";

const heroHeaderQuery = graphql`
    query {
        image: file(relativePath: { eq: "getting-started-banner.jpg" }) {
            childImageSharp {
                gatsbyImageData(
                    quality: 100
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                )
            }
        }
    }
`;

const FreeTrialPage = () => {
    const headerImage = useStaticQuery(heroHeaderQuery);
    const successfulSubmit = () => {
        navigate("/pre-training-questionnaire");
    };
    return (
        <Layout>
            <Seo
                title="Free Trial | FEAT Fitness"
                meta_title="Free Trial | FEAT Fitness"
                meta_desc="One Week Free Trial is standard for anyone to try FEAT, let's get you started we know you'll love it! FEAT Fitness is fun community training in your local outdoor park."
                slug="freetrial"
            />
            <main>
                <div>
                    {/* Hero card */}
                    <HeroHeader
                        title={"Welcome to FEAT Fitness"}
                        background_image={headerImage?.image}
                        options={[
                            {
                                title: "Free Trial",
                                to: `#${freeTrialId}`,
                                highlighted: true,
                            },
                            {
                                title: "First day of Training?",
                                to: `#${stepsToStartId}`,
                            },
                            {
                                title: "Membership Options",
                                to: `#${membershipOptionsId}`,
                            },
                        ]}
                        hideShading
                    />
                    {/* End hero */}

                    <div className="bg-feat-lightgrey" id={freeTrialId}>
                        <div className="max-w-lg mx-auto py-24 px-6">
                            <FreeTrialForm
                                headingClassNames="text-4xl text-heading uppercase text-center leading-6 font-medium text-gray-900 pb-4"
                                afterSubmit={successfulSubmit}
                            />
                        </div>
                    </div>

                    {/* Map and Location Details */}
                    <OurLocations />
                    {/* END Map and Location Details */}

                    {/* Training with FEAT Fitness */}
                    <StepsToStart id={stepsToStartId} />
                    {/* END Training with FEAT Fitness */}

                    {/* Instagram Feed */}
                    <InstagramFeed />
                    {/* End Instagram Feed */}
                </div>
            </main>
        </Layout>
    );
};

export default FreeTrialPage;
